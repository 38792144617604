<template>
    <v-container class="amp-module-page wide">
        <a-form ref="form" auto-submit @auto-submit="setData">
            <v-card
                class="pa-4"
                :class="{
                    'pa-5 px-6': $vuetify.breakpoint.mdAndUp
                }"
            >
                <v-row v-if="hasPublishIssues">
                    <v-col>
                        <a-alert
                            v-for="(issue, i) in publishIssues"
                            :key="i"
                            :message="issue.message"
                        />
                    </v-col>
                </v-row>
                <v-row dense>
                    <v-col>
                        <a-text-input
                            ref="title"
                            v-model="video.title"
                            :loading="isLoading"
                            label="Title"
                            rules="required|max:100"
                            rows="1"
                            auto-grow
                            textarea
                        />
                    </v-col>
                </v-row>
                <v-row dense>
                    <v-col>
                        <a-content-editor
                            ref="summary"
                            v-model="video.description"
                            :loading="isLoading"
                            :no-first-person="false"
                            label="Description"
                            rules="required|words:0,45"
                            :counter-value="getWordsCounter(45)"
                            one-line
                        />
                    </v-col>
                </v-row>
                <v-row v-if="$vuetify.breakpoint.smAndDown">
                    <v-col class="py-0 text-right col-sm-12">
                        <a-template-selector
                            :templates="templates"
                            :active-template="video.video_template"
                            :loading="isBusy"
                            path="infographic"
                            height="300"
                            @change="changeTemplate"
                        />
                        <a-infographic
                            ref="infographic"
                            label="Preview"
                            :template="video.video_template"
                        />
                        <a-template-selector
                            :templates="templates"
                            :active-template="video.video_template"
                            :loading="isBusy"
                            path="infographic"
                            height="300"
                            @change="changeTemplate"
                        />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="py-0 col-md-6 col-lg-6 col-sm-12">
                        <media-form
                            :template="video.video_template"
                            no-header
                            class="pa-0"
                        />
                    </v-col>
                    <v-col
                        v-if="$vuetify.breakpoint.mdAndUp"
                        class="py-0 text-right col-md-6 col-lg-6"
                    >
                        <a-template-selector
                            :templates="templates"
                            :active-template="video.video_template"
                            :loading="isBusy"
                            path="infographic"
                            height="300"
                            @change="changeTemplate"
                        />
                        <a-infographic
                            ref="infographic"
                            label="Preview"
                            :template="video.video_template"
                        />
                        <a-template-selector
                            :templates="templates"
                            :active-template="video.video_template"
                            :loading="isBusy"
                            path="infographic"
                            height="300"
                            @change="changeTemplate"
                        />
                    </v-col>
                </v-row>
            </v-card>
        </a-form>
    </v-container>
</template>

<script lang="ts">
import Component, { mixins } from 'vue-class-component';

import { Endpoint, MediaModuleEndpoint, WordsCounter } from '@/mixins';
import { InjectReactive, ProvideReactive, Watch } from '@/utils/decorators';

import { AForm } from '@/components/AForm';
import { AAlert } from '@/components/AAlert';
import { ATextInput } from '@/components/AForm/Inputs/ATextInput';
import { AContentEditor } from '@/components/AForm/Inputs/AContentEditor';

import { AInfographic } from '@/components/AInfographic';
import { ATemplateSelector } from '@/components/ATemplateSelector';
import { MediaForm } from '@/components/ASlideshow';

import type { NavigationGuardNext, Route } from 'vue-router';

import {
    AmpRow,
    AmpRowFirst,
    AmpRowLast,
    AmpValidation
} from '@/components/AmpModule/AmpPage';

import type { AmpModules } from '@/types/Announcement';
import type { Infographic as TInfographic } from '@/types/Infographic';
import type { SlideshowTemplate } from '@/types/Slideshow';
import type { ModuleLink } from '@/types/ModuleLink';

Component.registerHooks(['beforeRouteLeave']);

@Component({
    components: {
        AmpRow,
        AmpRowFirst,
        AmpRowLast,
        AmpValidation,
        AForm,
        ATextInput,
        AContentEditor,
        AInfographic,
        ATemplateSelector,
        MediaForm,
        AAlert
    }
})
export default class Infographic extends mixins(
    Endpoint,
    MediaModuleEndpoint,
    WordsCounter
) {
    $refs!: {
        form: InstanceType<typeof AForm>;
        title: InstanceType<typeof ATextInput>;
        summary: InstanceType<typeof ATextInput>;
        infographic: InstanceType<typeof AInfographic>;
    };

    @InjectReactive({
        from: 'modules',
        default() {
            return null;
        }
    })
    modules!: AmpModules;

    @Watch('modules')
    onModulesChanged() {
        this.onMounted();
    }

    @ProvideReactive()
    get user_id() {
        return this.video.user_id;
    }

    video: Partial<TInfographic> = {};

    endpoint = '/infographics/edit';

    templates: SlideshowTemplate[] = [];

    isSaving = false;

    link: ModuleLink[] = [
        {
            type: 'primary',
            label: 'Review',
            to: this.reviewLink
        }
    ];

    get moduleId() {
        return this.modules?.infographic_id;
    }

    get announcementId() {
        return this.$route.params.announcementId;
    }

    get sourceUrl() {
        return [this.endpoint, this.moduleId].join('/');
    }

    get hasPublishIssues() {
        return this.publishIssues.length > 0;
    }

    get publishIssues() {
        return this.$store.getters['broadcast/subscribe'](
            `${this.announcementId}-publish-infographic`
        );
    }

    get reviewLink() {
        return `/announcements/review/${this.announcementId}/infographic`;
    }

    get isBusy() {
        return this.isSaving || this.isLoading;
    }

    onMounted() {
        if (this.moduleId) {
            this.setPrePublishHook();

            this.load();
        } else {
            this.askToCreate();
        }
    }

    setPrePublishHook(isSet = true) {
        this.$emit('pre-publish', isSet ? this.prePublish.bind(this) : null);
    }

    askToCreate() {
        if (this.modules && !this.moduleId) {
            this.$emit('create', this.endpoint);
        }
    }

    onData(data: {
        video: TInfographic;
        infographic_templates: SlideshowTemplate[];
    }) {
        if (data.video) {
            this.video = data.video;
            this.templates = data.infographic_templates;

            this.protectRoute();

            this.emitLinks();

            return this.setSaved();
        } else {
            this.review();
        }
    }

    beforeRouteLeave(to: Route, from: Route, next: NavigationGuardNext) {
        this.setPrePublishHook(false);

        if (this.$refs.form.isDirty) {
            return this.save(false).then(() => next());
        } else {
            return next();
        }
    }

    save(foreground = true) {
        this.setSaving();

        return this.setData()
            .then(async data => {
                if (!data.meta.success && foreground) {
                    this.notifyError();

                    return false;
                }

                await this.setSaved();

                if (foreground) {
                    this.onSave();
                }
            })
            .finally(this.setSaving.bind(this, false));
    }

    setData() {
        return this.$http
            .post(this.sourceUrl, this.getDataToSave())
            .then(({ data }) => {
                // update local instance with computed props, if any
                if (data.video) {
                    this.video = data.video;
                }

                return data;
            })
            .catch(error => {
                if (!error.isIntercepted) {
                    this.$store.dispatch('notification/error', error);
                }

                return {};
            });
    }

    getDataToSave() {
        return {
            id: this.video.id,
            video_template_id: this.video.video_template_id,
            title: this.video.title,
            description: this.video.description,
            link: null,
            media_resources: this.collectMediaResources(
                this.video.video_template
            ),
            video_captions: this.collectVideoCaptions(this.video.video_template)
        };
    }

    setSaving(isSaving = true) {
        this.isSaving = isSaving;
    }

    async setSaved() {
        return this.$refs.form.reset();
    }

    async changeTemplate(id: number) {
        if (this.$refs.form?.isDirty) {
            await this.save(false);
        }

        this.setSaving();
        // notify server about new template
        await this.$http.post(this.sourceUrl, {
            id: this.video.id,
            change_template: id
        });
        // regenerate Infographic using new template
        await this.$http.get(
            `${this.endpoint}?announcement_id=${this.announcementId}&new_template=${id}`
        );
        // reload entire Amp since Infographic receives new ID
        this.$emit('reload');
        this.setSaving(false);
    }

    async onSave() {
        const isValid = await this.revalidate();

        if (isValid) {
            this.review();
        }
    }

    async revalidate() {
        // reset existing errors
        this.$store.dispatch(
            'broadcast/reset',
            `${this.announcementId}-publish-infographic`
        );

        const isValid = await this.$refs.form.validate();

        if (!isValid) {
            this.notifyInvalid();
        }

        return isValid;
    }

    review() {
        this.$router.push(this.reviewLink);
    }

    protectRoute() {
        if (!this.video.is_editable) {
            this.review();
        }
    }

    emitLinks() {
        this.$emit('links', this.link);
    }

    prePublish() {
        if (!this.$refs.form?.isDirty) {
            return this.revalidate();
        }

        this.setSaving();

        return this.setData()
            .then(async data => {
                if (data.meta.success) {
                    return this.revalidate();
                } else {
                    this.notifyError();

                    return false;
                }
            })
            .finally(this.setSaving.bind(this, false));
    }

    notifyInvalid() {
        this.$store.dispatch(
            'notification/info',
            'Infographic saved successfully. Please check the form for errors.'
        );
    }

    notifyError() {
        this.$store.dispatch(
            'notification/error',
            'Unable to save Infographic. Please check the form for errors.'
        );
    }
}
</script>
